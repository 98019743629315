<!--
 * @Description: 账户信息
 * @Author: luocheng
 * @Date: 2022-03-28 17:41:15
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-29 14:04:59
-->
<template>
  <div class="account-info" v-if="userInfo">
    <!-- 头像 -->
    <section class="info-item avatar-item">
      <span class="label">头像</span>
      <div class="info-box">
        <img :src="userInfo.avatar || defaultAvatar" alt="" class="avatar">
      </div>
      <div class="actions">
        <a href="javascript:;" class="modify-avatar">修改
          <!-- 头像 -->
          <FileUpload
            ref="modifyAvatar"
            :files="preloadAvatar"
            :multiple="false"
            :fileTypeList="['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd']"
            @extra-files="onFileUpload"
            :fileAccept="'image/*'"
          ></FileUpload>
        </a>
      </div>
    </section>
    <!-- 用户名 -->
    <section class="info-item name-item">
      <span class="label">用户名</span>
      <div class="info-box">
        <el-input v-model="modifyName" placeholder="请输入用户名" clearable v-if="isModifyName"></el-input>
        <p class="text" v-else>{{ userInfo.name || '' }}</p>
      </div>
      <div class="actions">
        <template v-if="isModifyName">
          <a href="javascript:;" class="cancel" @click="onCancelName">取消</a>
          <a href="javascript:;" @click="onSaveName">保存</a>
        </template>
        <a href="javascript:;" @click="toModifyName" v-else>编辑</a>
      </div>
    </section>
    <!-- 密码 -->
    <section class="info-item">
      <span class="label">密码</span>
      <div class="info-box">
        <p class="text password">········</p>
      </div>
      <div class="actions">
        <a href="javascript:;" @click="showModifyPassword = true">修改</a>
      </div>
    </section>
    <!-- 手机号 -->
    <section class="info-item name-item">
      <span class="label">手机号</span>
      <div class="info-box">
        <el-input v-model="modifyPhone" placeholder="请输入用户名" clearable v-if="isModifyPhone"></el-input>
        <p class="text" v-else>{{ replacePhone(userInfo.phone) }}</p>
      </div>
      <div class="actions">
        <template v-if="isModifyPhone">
          <a href="javascript:;" class="cancel" @click="onCancelPhone">取消</a>
          <a href="javascript:;" @click="onSavePhone">保存</a>
        </template>
        <a href="javascript:;" v-else @click="toModifyPhone">修改</a>
      </div>
    </section>
    <section class="info-item border-item">
      <span class="label">企业微信</span>
      <div class="info-box">
        <p class="text">{{ userInfo.corp_wechat_id ? '已绑定' : '未绑定' }}</p>
      </div>
      <div class="actions">
        <a href="javascript:;" v-if="userInfo.corp_wechat_id" @click="onRelieve">解除绑定</a>
        <a href="javascript:;" v-else @click="onBind">绑定</a>
      </div>
    </section>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="showModifyPassword"
      width="400px"
      destroy-on-close
      :before-close="onClose"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="modifyPasswordForm" :model="modifyPasswordForm" :rules="rules">
        <el-form-item label="当前密码" prop="password">
          <el-input v-model="modifyPasswordForm.password" placeholder="请输入当前密码" show-password clearable></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="modifyPasswordForm.newPassword" placeholder="密码长度8-16位,必须包含数字和字母!" show-password clearable></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmPassword">
          <el-input v-model="modifyPasswordForm.confirmPassword" placeholder="请确认新密码" show-password clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose(null)" v-if="!forcedPassword">取 消</el-button>
        <el-button type="warning" @click="onBackLogin" v-else>返回登录</el-button>
        <el-button type="primary" @click="onModifyPassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileUpload from '@/components/fileUpload/newFiles/index';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { setToken } from '@/utils/tools';

export default {
  name: 'AccountInfo',
  components: {
    FileUpload
  },
  data() {
    /* eslint-disable */
    const checkNewPassword = (rule, value, callback) => {
      const reg = /^(?=.*[0-9\!@#\$%\^&\*])(?=.*[a-zA-Z]).{8,16}$/;
      if (!value) {
        callback(new Error('请输入新密码!'));
      } else if (!reg.test(value)) {
        callback(new Error('密码由数字、字母、特殊字符组成,长度8-16位,必须包含数字和字母!'))
      } else {
        callback();
      }
    }
    const checkConfirmPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请确认新密码!'));
      } else if (value !== this.modifyPasswordForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {
      userInfo: null,
			defaultAvatar: require('@/assets/images/logo1.png'),
      preloadAvatar: [],
      // 修改密码
      showModifyPassword: false,
      modifyPasswordForm: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        password: [{ required: true, message: '请输入当前密码', trigger: 'blur' }],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: checkNewPassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: checkConfirmPassword, trigger: 'blur' }
        ]
      },
      // 用户名
      isModifyName: false,
      modifyName: '',
      // 手机号
      isModifyPhone: false,
      modifyPhone: '',
      forcedPassword: 0
    }
  },
  created() {
    this.userInfo = this.$GetUserInfo();
    this.forcedPassword = +this.$route.query?.forcedPassword
    if (+this.forcedPassword === 1) {
      this.showModifyPassword = true;
    }
    if(localStorage.getItem('updatePwd') == 0 ) {
      this.$message.error('请先修改密码!');
    }
  },
  methods: {
    /**
     * @desc: 上传头像图片
     */
    onFileUpload(data) {
      if (!data || !data.length) return;
      this.$confirm('是否确认修改头像？').then(() => {
        const file = data[data.length - 1];
        this.modifyInfo({
          avatar: file?.url
        });
        this.preloadAvatar = [];
      }).catch(() => {
        this.preloadAvatar = [];
      });
    },
    /**
     * @desc: 前往编辑名称
     */
    toModifyName() {
      this.modifyName = this.userInfo.name || '';
      this.isModifyName = true;
    },
    /**
     * @desc: 编辑名称
     */
    onSaveName() {
      this.modifyInfo({
        name: this.modifyName
      });
    },
    /**
     * @desc: 取消编辑名称
     */
    onCancelName() {
      this.isModifyName = false;
      this.modifyName = '';
    },
    /**
     * @desc: 提交修改密码
     */
    onModifyPassword() {
      this.$refs.modifyPasswordForm.validate(valid => {
        if (!valid) return false;
        const loading = this.$loading();
        dataInterface({
          old_password: this.modifyPasswordForm.password,
          password: this.modifyPasswordForm.newPassword,
          password_confirmation: this.modifyPasswordForm.confirmPassword,
        }, 'api/user/changeNewPassword').then(res => {
          loading.close();
          if (res.status === 200 && res.data.code === 200) {
            localStorage.setItem('updatePwd',1)
            this.showModifyPassword = false;
            this.$message.success('修改成功！即将自动跳转到登录页重新登录！');
            setTimeout(()=>{
              this.$router.replace({
                name: 'Login'
              });
            },1500)
            // this.$confirm('密码已修改，是否立即重新登录？', '提示', {
            //   confirmButtonText: '前往登录',
            //   cancelButtonText: localStorage.getItem('updatePwd')?'':'取消'
            // }).then(() => {
            //   this.onQuit();
            // }).catch(() => {});
            return;
          } else {
            this.$message.error('操作失败！');
          }
        }).catch(err => {
          console.log(err);
          loading.close();
          this.$message.error('操作失败！');
        })
      });
    },
    /**
		 * @desc: 退出登录
		 */
		onQuit() {
			// 移除路由文件重新获取一次
			setToken('');
			sessionStorage.clear();
			localStorage.clear();
			this.$router.replace({
				name: 'Login'
			});
			eventBus.$emit('updateRoute');
			// 刷新数据
			sessionStorage.removeItem('navHistoryList');
			this.$store.commit('setNavHistory', []);
			this.$store.commit('setTargetGlobalTab', '');
		},
    /**
     * @desc: 手机号
     */
    toModifyPhone() {
      this.isModifyPhone = true;
      this.modifyPhone = this.userInfo.phone || '';
    },
    /**
     * @desc: 取消编辑手机号
     */
    onCancelPhone() {
      this.modifyPhone = '';
      this.isModifyPhone = '';
    },
    /**
     * @desc: 保存手机号
     */
    onSavePhone() {
      const phoneReg =
			/^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
      if (!phoneReg.test(this.modifyPhone)) {
        this.$message.warning('请输入正确手机号!');
        return;
      }
      this.modifyInfo({
        phone: this.modifyPhone
      });
    },
    /**
     * @desc: 解除绑定
     */
    onRelieve() {
      this.$confirm('是否确认解除企业微信绑定?').then(() => {
        const loading = this.$loading();
        let archiType = '';
        const typeString = this.$GetTargetArchi('archiType');
        if (typeString === 'project') {
          archiType = 2;
        } else if ((typeString === 'company')) {
          archiType = 1;
        } else {
          loading.close();
          this.$message.error('操作失败');
          return;
        }
        dataInterface({
          archiID: this.$GetTargetArchi('archiId'),
          archiType,
          user_id: this.userInfo.id
        }, 'api/wechat/deleteSync', 'DELETE').then(res => {
          loading.close();
          if (res.status === 200) {
            this.$message.success('已解除企业微信绑定!');
            this.refreshUserInfo();
          } else {
            this.$message.error('操作失败');
          }
        }).catch(err => {
          console.log(err, '2222')
          loading.close();
          this.$message.error('操作失败');
        })
      }).catch(() => {});
    },
    /**
     * @desc: 绑定企业微信
     */
    onBind() {
      const loading = this.$loading();
      let archiType = '';
      const typeString = this.$GetTargetArchi('archiType');
      if (typeString === 'project') {
        archiType = 2;
      } else if ((typeString === 'company')) {
        archiType = 1;
      } else {
        loading.close();
        this.$message.error('操作失败');
        return;
      }
      dataInterface({
        archiID: this.$GetTargetArchi('archiId'),
        archiType,
        user_id: this.userInfo.id
      }, 'api/wechat/syncOne', 'POST').then(res => {
        loading.close();
        if (res.status === 200) {
          this.$message.success('绑定成功!');
          this.refreshUserInfo();
        } else {
          this.$message.error('操作失败');
        }
      }).catch(err => {
        console.log(err, '2222')
        loading.close();
        this.$message.error('操作失败');
      })
    },
    /**
     * @desc: 提交编辑
     * @param {Object} params 参数
     */
    modifyInfo(params) {
      const loading = this.$loading();
      dataInterface({
        __method_name__: 'updateData',
        object_uuid:'844a47c6-6380-46dd-bc4a-d73b6245175f',
        view_uuid:'1020e62a-c119-4bcc-882a-eb0bcf760a86',
        data_id: this.userInfo.id,
        ...params
      }).then(res => {
        if (res.status === 200) {
          this.$message.success('操作成功！');
          this.refreshUserInfo();
          this.onCancelName();
          this.onCancelPhone();
        }
      }).catch(err => {
        console.log(err);
        loading.close();
        this.$message.error('操作失败！');
      });
    },
    /**
     * @desc: 刷新用户信息
     */
    refreshUserInfo() {
      const loading = this.$loading();
      dataInterface({
        __method_name__: 'dataInfo',
        object_uuid:'844a47c6-6380-46dd-bc4a-d73b6245175f',
        view_uuid:'1020e62a-c119-4bcc-882a-eb0bcf760a86',
        data_id: this.userInfo.id,
        transcode: 0
      }).then(res => {
        if (!res.status) return;
        const { avatar, name, phone } = res?.data?.data || {};
        localStorage.setItem('userInfo', JSON.stringify({
          ...this.userInfo,
          avatar,
          name,
          phone
        }));
        this.userInfo = this.$GetUserInfo();
        eventBus.$emit('updateUserInfo');
        loading.close();
      }).catch(err => {
        console.log(err);
        loading.close();
      });
    },
    /**
     * @desc: 手机号处理
     */
    replacePhone(phone) {
      if(phone){
         return phone.toString().replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
      }
      return ''
    },
    /**
     * @desc: 取消修改密码
     */
    onClose(done) {
      this.modifyPasswordForm = {
        password: '',
        newPassword: '',
        confirmPassword: ''
      };
      if (done) {
        done();
      }
      this.showModifyPassword = false;
    },
    /**
     * @desc: 返回登陆
     */
    onBackLogin() {
      this.$router.replace({
        name: 'Login'
      });
    }
  }
}
</script>

<style lang="less" scoped>
@itemHeight: 22px;
.account-info{
  width: 100%;
  height: auto;
  .info-item{
    min-height: @itemHeight;
    margin-top: 24px;
    padding: 0 40px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    &.border-item{
      border-bottom: 1px solid #E9EBF0;
      padding-bottom: 24px;
    }
    &.avatar-item{
      .label{
        line-height: 56px;
      }
      .actions a{
        display: block;
        height: 56px;
        line-height: 62px;
      }
    }
    &.name-item{
      .label{
        line-height: 32px;
      }
      .info-box .text{
        line-height: 32px;
      }
      .actions a{
        line-height: 32px;
      }
    }
    .label{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4D5358;
      line-height: 22px;
      width: 58px;
    }
    .info-box{
      box-sizing: border-box;
      padding: 0 24px;
      flex: 1;
      min-height: 22px;
      .text{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #121619;
        line-height: 22px;
        &.password{
          font-weight: bold;
          font-size: 26px;
          letter-spacing: -4px;
        }
      }
      .avatar{
        height: 56px;
        width: 56px;
        border-radius: 8px;
      }
    }
    .actions{
      width: auto;
      min-height: 22px;
      box-sizing: border-box;
      a{
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0076FF;
        line-height: 22px;
        &.cancel{
          color: #4D5358;
        }
        &.modify-avatar{
          position: relative;
          overflow: hidden;
          .file-upload{
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            overflow: hidden;
            padding: 0;
            opacity: 0;
          }
        }
      }
      a+a{
        margin-left: 10px;
      }
    }
  }
}
</style>